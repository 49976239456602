.mi-xl {
    width:1114px !important;
    padding-left: 0 !important;
  }
  
  @media (min-width: 1200px) {
    .modal-xl {
        width:1114px
    }
}


.floating-div {
  position: fixed;
  top: 220px; /* Cambia bottom a top */
  right: 20px; /* Ajusta según sea necesario */

  padding: 10px;

  border-radius: 8px;
  z-index: 1000; /* Asegúrate de que el div esté por encima de otros elementos */
  display: flex;
  gap: 10px; /* Espacio entre los botones */
}

.floating-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.floating-button:hover {
  background-color: #0056b3;
}

.statusEdicion {
  width: 180px !important ;
}