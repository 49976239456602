body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-white{
  color: white !important;
}
.title-modal-custom-color{
  color: rgba(255,255,255,.90);
}
.title-modal-custom{
  font-size: 23px;
  font-weight: bold;

}

.zi-99999{
  z-index: 99999 !important;
}


.lx-container {
 width: 95% !important;
}
.pagn{
  text-align: center !important;
}
.modal-xlg{
  width: 95% !important;
}
.mxw-50{
  max-width: 50% !important;
}

.css-2b097c-container{
  width: 95%;
}