.header {
      background-color: #0d28f8;
    border-color: #223ffd;
    color: #fff !important;
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.accordion-card {
    background-color: #555f64;
    /* 37474f */
    margin-bottom: 0 !important;
    /* cursor: pointer; */
}
.accordion-button {
    background-color: #555f64 !important;
    border: none;
    width: 100%;
  }
.accordion-header {
    border: none;
    background-color: none !important;
    padding: 7px !important;
    color: lightgray;
    margin-bottom: 0 !important;
    background-color: #555f64;
    font-size: 15px !important;
}

.accordion-collapse {
    /* padding: 3px 5px 3px 20px; */
    background-color: rgb(230, 230, 230);
    margin-bottom: 0 !important;
}

.accordion-body {
    padding: 10px 10px 10px 30px;
}

.icon-medium  {
    width: 17px;
    height: 17px;
}

.icon-small  {
    width: 15px;
    height: 15px;
}

.icon-agregadoABD {
    margin-left: 5px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.col-file-icons {
    display: flex;
    margin-bottom: 3px;
}

.celda-fila {
    padding: 0 5px;  
}

.header-detalle {
    background-color: floralwhite;
    font-weight: bold;
}

.cell-detalle:hover{
 color: #000;
 background: rgb(235, 244, 253);
 cursor: pointer;
 opacity: 0.8;

}

