
  .titleDetail{
    padding: 10px 0 10px 0;
    text-align: center;
    background: rgb(1, 89, 221);
    /* opacity: 0.2; */
    color: #f1f1f1;
  }
  
  .inptOff{
    width: 100%;
    border: none;
  }
  .inptOn{
    background-color: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid #d1d0d0;
  }
  /* 
  .ant-table-thead > tr > th {
  background: #0281f0 !important;  
  
  } */
  
  .ant-table-thead > tr > th{
    /* padding: 5px 5px !important; */
    /* background: rgb(243, 244, 245) !important; */
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px 5px !important;
  } 
  /* .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 6px 6px ;
    overflow-wrap: break-word;
  } */
  
  .stylePdf{
    color: #fff;
    background: #337ab7;
    border: rgba(6, 66, 134, 0.938) 1px solid;
    border-radius: 5px;
    cursor: pointer; 
    padding: 6px 25px 8px 1px;
    width: 100%;
    padding-left: 4px;
    /* float: right; */
    
    /* border: 1px solid #000000; */


  }
  .stylePdf:hover{
    background: #0281f0;
    /* font-size: 13px; */
  }
  .divstylePdf{
    margin-left: 50%;
    /* border: 1px solid #000000; */

  }
  .divUpdate{
    margin-left: 80%;

  }
  .span-rep{
    /* border: 1px solid #000000; */
  }

  .btnGenerate{
    text-align: center;
    color: #fff;
    background: #337ab7;
    border: rgba(6, 66, 134, 0.938) 1px solid;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer; 

    /* border: 1px solid #000000; */

  }
  .btnGenerate:hover{
    background: #0281f0;
    /* font-size: 13px; */
  
  }