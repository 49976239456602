.ListViewTitulo {
    background-color: rgb(21, 119, 152);
    color: white;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    padding: 15px;
}

.ListViewBuscar {
    border: 1px solid rgb(21, 119, 152);
    height: 60px;
    padding: 10px;
}

.ListViewContainerPrincipal {
    border: 1px solid rgb(21, 119, 152);
    max-height: 300px;
    overflow-y: scroll;
}

.ListViewRegistro {
    border-bottom: 1px solid rgb(21, 119, 152);

    max-height: 70px; /* Límite de altura */
    padding: 10px;
}